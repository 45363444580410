import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'react-bootstrap';
import Sidebar from '../base/sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BsEye, BsPencilSquare, BsTrash, BsTrashFill } from 'react-icons/bs';

interface Item {
    id: number;
    category: string;
    title: string;
    author: string;
    status: string;
    // Add more properties as per your item structure
}

export default function LongStory() {
    const [items, setItems] = useState<Item[]>([]);
    const navigate = useNavigate();
    const [author, setAuthor] = useState<string | null>(null);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        const storedAuthor = localStorage.getItem('author');
        const storedRole = localStorage.getItem('role');
        setAuthor(storedAuthor);
        setRole(storedRole);
    }, []);


    const [draftsPage, setDraftsPage] = useState(1);
    const [approvalPage, setApprovalPage] = useState(1);
    const [latestPage, setLatestPage] = useState(1);

    const draftsPerPage = 10;
    const approvalPerPage = 10;
    const latestPerPage = 10;

    const indexOfLastDraft = draftsPage * draftsPerPage;
    const indexOfFirstDraft = indexOfLastDraft - draftsPerPage;
    const currentDrafts = items.slice(indexOfFirstDraft, indexOfLastDraft);

    const indexOfLastApproval = approvalPage * approvalPerPage;
    const indexOfFirstApproval = indexOfLastApproval - approvalPerPage;
    const currentApprovals = items.slice(indexOfFirstApproval, indexOfLastApproval);

    const indexOfLastLatest = latestPage * latestPerPage;
    const indexOfFirstLatest = indexOfLastLatest - latestPerPage;
    const currentLatest = items.slice(indexOfFirstLatest, indexOfLastLatest);


    const hasMatchingDrafts = currentDrafts.some(item => item.status === 'Pending');

    const nextDraftsPage = () => {
        if (draftsPage < Math.ceil(items.length / draftsPerPage)) {
            setDraftsPage(draftsPage + 1);
        }
    };

    const prevDraftsPage = () => {
        if (draftsPage > 1) {
            setDraftsPage(draftsPage - 1);
        }
    };

    const nextApprovalPage = () => {
        if (approvalPage < Math.ceil(items.length / approvalPerPage)) {
            setApprovalPage(approvalPage + 1);
        }
    };

    const prevApprovalPage = () => {
        if (approvalPage > 1) {
            setApprovalPage(approvalPage - 1);
        }
    };

    const nextLatestPage = () => {
        if (latestPage < Math.ceil(items.length / latestPerPage)) {
            setLatestPage(latestPage + 1);
        }
    };

    const prevLatestPage = () => {
        if (latestPage > 1) {
            setLatestPage(latestPage - 1);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://pulpmagbackoffice.pulp.ph/api/long-stories');
            setItems(response.data.longStory.data);
            console.log(setItems);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEditShortstory = (id: number) => {
        navigate('/update/longStory', { state: { id } }); // Navigate to the ShortStoryEditor without ID in the URL but pass the id through state
    };

    const handleViewShortstory = (id: number) => {
        window.open(`https://richardcalipay.pulp.ph/article/long_story/${id}`, '_blank');
    };

    const handleDelete = async (id: number) => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            };

            await axios.delete(`https://pulpmagbackoffice.pulp.ph/api/longStory/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            });
            alert('Content Deleted Successfully !!');// Handle success (e.g., show a success message or redirect)
            window.location.reload();
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <React.Fragment>
            <div className='flex font-graphik'>
                <Sidebar />
                <main className="container flex justify-center bg-gray-100">
                    <div className="w-full">
                        <div className='Dashboard-container flex place-content-between'>
                            <h2 className="mt-5 font-semibold">List of All Long Stories</h2>
                            <div className='mt-5'>
                                <Dropdown as={ButtonGroup}>
                                    <Button variant='danger'>Create New</Button>
                                    <DropdownToggle split variant='danger' id="dropdown-split-basic" />
                                    <DropdownMenu>
                                        <DropdownItem href='/shortStory'>Short Story</DropdownItem>
                                        <DropdownItem href='/longStory'>Long Story</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <h3 className='font-semibold mb-1'>Pending Long Stories</h3>
                            {hasMatchingDrafts ? (
                                <>
                                    <Table hover>
                                        <thead className="bg-gray-200">
                                            <tr>
                                                <th>Category</th>
                                                <th>Title</th>
                                                <th>Author</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentDrafts.map(item => (
                                                item.status === 'Pending' && (
                                                    <tr key={item.id}>
                                                        <td>{item.category}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.author}</td>
                                                        <td>{item.status}</td>
                                                        <td>
                                                            <button onClick={() => handleViewShortstory(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='preview'><BsEye /></button>
                                                            <span> </span>
                                                            <button onClick={() => handleEditShortstory(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='edit'><BsPencilSquare /></button>
                                                            <span> </span>
                                                            <button onClick={() => handleDelete(item.id)} type='button' className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </Table>
                                    <div className="flex justify-center items-center mt-4 space-x-4">
                                        <button
                                            onClick={prevDraftsPage}
                                            className="px-4 py-2 rounded disabled:opacity-50 text-white bg-gray-500"
                                            disabled={draftsPage === 1}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            onClick={nextDraftsPage}
                                            className="px-4 py-2 rounded disabled:opacity-50 bg-red-500"
                                            disabled={draftsPage === Math.ceil(items.length / draftsPerPage)}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <p>No drafts available.</p>
                            )}
                        </div>

                        {/* {(role === 'IT' || role === 'Magazine') && (
                            <div className="container mt-5">
                                <h3 className='mb-1 font-semibold'>For Approval</h3>
                                <Table hover>
                                    <thead className="bg-gray-400">
                                        <tr>
                                            <th>Category</th>
                                            <th>Title</th>
                                            <th>Author</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentApprovals.map(item => (
                                            item.status === 'Pending' && (
                                                <tr key={item.id}>
                                                    <td>{item.category}</td>
                                                    <td>{item.title}</td>
                                                    <td>{item.author}</td>
                                                    <td>{item.status}</td>
                                                    <td>
                                                        <button onClick={() => handleViewShortstory(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='view'><BsPencilSquare /></button>
                                                        <span> </span>
                                                        <button onClick={() => handleDelete(item.id)} className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                                    </td>
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                </Table>
                                <div className="flex justify-center items-center mt-4 space-x-4">
                                    <button
                                        onClick={prevApprovalPage}
                                        className="px-4 py-2 rounded disabled:opacity-50"
                                        disabled={approvalPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={nextApprovalPage}
                                        className="px-4 py-2 rounded disabled:opacity-50"
                                        disabled={approvalPage === Math.ceil(items.length / approvalPerPage)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )} */}

                        <div className="container mt-5">
                            <h3 className='font-semibold mb-1'>All Published Long Stories</h3>
                            <Table hover>
                                <thead className="bg-gray-200">
                                    <tr>
                                        <th>Category</th>
                                        <th>Title</th>
                                        <th>Author</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentLatest.map(item => ( 
                                        item.status === 'Approved' && (
                                            <tr key={item.id}>
                                                <td>{item.category}</td>
                                                <td>{item.title}</td>
                                                <td>{item.author}</td>
                                                <td>{item.status}</td>
                                                <td>
                                                    <button onClick={() => handleViewShortstory(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='preview'><BsEye /></button>
                                                    <span> </span>
                                                    <button onClick={() => handleEditShortstory(item.id)} className='bg-red-600 rounded-sm text-white text-xl' data-tooltip-id='edit'><BsPencilSquare /></button>
                                                    <span> </span>
                                                    <button onClick={() => handleDelete(item.id)} className='bg-gray-600 rounded-sm text-white text-xl' data-tooltip-id='delete'><BsTrash /></button>
                                                </td>
                                                <td></td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </Table>
                            <div className="flex justify-center items-center mt-4 space-x-4">
                                <button
                                    onClick={prevLatestPage}
                                    className="px-4 py-2 rounded disabled:opacity-50 text-white bg-gray-500"
                                    disabled={latestPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={nextLatestPage}
                                    className="px-4 py-2 rounded disabled:opacity-50 bg-red-500"
                                    disabled={latestPage === Math.ceil(items.length / latestPerPage)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


        </React.Fragment>
    )
}
