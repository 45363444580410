import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/dashboard');
        }, 3000); // 5000 milliseconds = 5 seconds

        // Cleanup the timer if the component is unmounted before the time is up
        return () => clearTimeout(timer);
    }, [navigate]);
    return (
        <>
            <div className="not-found-container">
                <h2>404 - Page Not Found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
                <p>you will be redirected to home shortly</p>
            </div>
        </>
    )
};