// src/App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './output.css';
import Login from './Components/Login/index';
import Dashboard from './Components/Dashboard/index';
import ShortStory from './Components/ShortStoryEditor';
import NotFound from './Components/404';
import ViewShortPost from './Components/View Short Story Post';
import LongStory from './Components/LongStoryEditor';
import Register from './Components/Register';
import UpdateShortStory from './Components/ShortStoryEditor/updateShortStory';
import ViewShortStory from './Components/View Short Story Post/viewShortStories';
import ViewLongPost from './Components/View Long Post';
import ViewLongStory from './Components/View Long Post/viewLongStories';
import UpdateLongStory from './Components/LongStoryEditor/updateLongStory';
import { Tooltip as ReactTooltip } from 'react-tooltip';
export default function App() {

  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/shortStory" element={<ShortStory />} />
        <Route path="/longStory" element={<LongStory />} />
        <Route path="/view/post/shortStory" element={<ViewShortPost />} />
        <Route path="/view/shortStory" element={<ViewShortStory />} />
        <Route path="/update/shortStory" element={<UpdateShortStory />} />
        <Route path="/view/post/longStory" element={<ViewLongPost />} />
        <Route path="/view/longStory" element={<ViewLongStory />} />
        <Route path="/update/longStory" element={<UpdateLongStory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>




      <ReactTooltip
        id="edit"
        place="right"
        content="Edit"
      />

      <ReactTooltip
        id="preview"
        place="right"
        content="Preview"
      />

      <ReactTooltip
        id="delete"
        place="right"
        content="Delete"
      />
            <ReactTooltip
        id="feature"
        place="right"
        content="Feature Coming Soon"
      />

    </Router>
  );
}