import axios from "axios";
import { useState, useEffect } from "react";
import { BsBoxArrowRight, BsFile, BsFiles, BsHouse, BsPersonCircle, BsShield } from "react-icons/bs"
import { useLocation, useNavigate } from "react-router-dom";
import pulpLogo from "../assets/img/pulpmag.png";

export default function Sidebar() {
    const [author, setAuthor] = useState<string | null>(null);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        const storedAuthor = localStorage.getItem('author');
        const storedRole = localStorage.getItem('role');        
        if (storedAuthor) {
            setAuthor(storedAuthor);
        }
        if (storedRole) {
            setRole(storedRole);
        }
    }, []);
    const navigate = useNavigate();

    const location = useLocation();

    const isActive = (path: string) => location.pathname === path;


    const handleLogout = async () => {
        // Clear the token from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('author');
        localStorage.removeItem('role');
        await axios.post('https://pulpmagbackoffice.pulp.ph/api/logout');
        // Optionally, clear the default Authorization header for Axios
        delete axios.defaults.headers.common['Authorization'];
        // Redirect to the login page
        alert('Logged Out Successfully');
        navigate('/');
    };


    return (
        <>
            <aside className="w-80 ">
                <div className="p-4 text-center">
                    <div className="pulp-logo flex items-center justify-center h-auto w-auto">
                        <img src={pulpLogo} className="h-[77px] w-[90px]" alt="pulp logo" />

                    </div>
                    <br />
                    <br />
                    <div className="profile container flex items-center space-x-4">
                        <div className="picture flex items-center justify-center">
                            <BsPersonCircle className="text-5xl" />
                        </div>
                        <div className="name-role-container">
                            <h2 className="font-semibold text-lg">{author}</h2>
                            <p className="text-sm"><i>{role}</i></p>
                        </div>
                    </div>
                </div>
                <nav className="mt-5">
                    <ul>
                        <li className={`hover:text-red-500 hover:font-semibold hover:bg-gray-300 ${isActive("/dashboard") ? 'text-red-500 font-semibold' : ''}`}>
                            <a href="/dashboard" aria-current={isActive("/dashboard") ? 'page' : undefined} className="flex items-center p-3">
                                <BsHouse className="mr-3" /> Dashboard
                            </a>
                        </li>
                        <li className={`hover:text-red-500 hover:font-semibold hover:bg-gray-300 ${isActive('/view/post/shortStory') ? 'text-red-500 font-semibold' : ''}`}>
                            <a href="/view/post/shortStory" aria-current={isActive('/view/post/shortStory') ? 'page' : undefined} className="flex items-center p-3">
                                <BsFile className="mr-3" /> Short Story
                            </a>
                        </li>
                        <li className={`hover:text-red-500 hover:font-semibold hover:bg-gray-300 ${isActive('/view/post/longStory') ? 'text-red-500 font-semibold' : ''}`}>
                            <a href="/view/post/longStory" aria-current={isActive('/view/post/longStory') ? 'page' : undefined} className="flex items-center p-3">
                                <BsFiles className="mr-3" /> Long Story
                            </a>
                        </li>
                        <li className={`hover:text-red-500 hover:font-semibold ${isActive('/admin') ? 'text-red-500 font-semibold' : ''}`}>
                            {/* <a href="/dashboard" aria-current={isActive('/admin') ? 'page' : undefined} className="flex items-center p-3" data-tooltip-id="feature">
                                <BsShield className="mr-3" /> Admin
                            </a> */}
                            <p className="flex items-center p-3" data-tooltip-id="feature"><BsShield className="mr-3" /> Admin</p>
                        </li>
                        <li className={`hover:text-red-500 hover:font-semibold hover:bg-gray-300`}>
                            <a href="/" onClick={handleLogout} className="flex items-center p-3">
                                <BsBoxArrowRight className="mr-3" /> Logout
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
        </>
    )
}
