import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import he from "he";

interface LongStory {
    category: string;
    title: string;
    seo_title: string;
    description: string;
    body: string;
    image: string;
}

const ViewLongStory: React.FC = () => {
    const location = useLocation();
    const id = location.state && location.state.id;
    const [item, setItem] = useState<LongStory | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if ((window as any).instgrm) {
            (window as any).instgrm.Embeds.process();
        }
    })

    useEffect(() => {
        const fetchItem = async (id: number) => {
            try {
                const response = await axios.get(`https://pulpmagbackoffice.pulp.ph/api/long-stories/${id}`);
                const shortStory = response.data.shortStory[0];
                if (shortStory) {
                    setItem(shortStory);
                }
            } catch (error) {
                setError('Error fetching item');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchItem(id);
        }
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            {item && (
                <>
                    <h1>{item.title}</h1>
                    <h2>{item.seo_title}</h2>
                    <p>{item.description}</p>
                    <img src={`https://pulpmagbackoffice.pulp.ph/storage/${item.image}`}/>
                    <div dangerouslySetInnerHTML={{ __html: he.decode(item.body) }} />
                </>
            )}
        </>
    );
};

export default ViewLongStory;
