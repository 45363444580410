import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-quill/dist/quill.snow.css';

interface Image {
    id: number;
    url: string;
}

interface ChooseImageProps {
    onImageSelect: (embedCode: string) => void;
}

export default function ChooseImage({ onImageSelect }: ChooseImageProps): JSX.Element {
    const [images, setImages] = useState<Image[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get<{ images: Record<string, string> }>('https://pulpmagbackoffice.pulp.ph/api/imageList', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                const imagesData = Object.entries(response.data.images).map(([id, url]) => ({ id: parseInt(id), url }));
                setImages(imagesData);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
    
        fetchImages();
    }, []);
    

    const handleImageSelect = (imageUrl: string) => {
        const embedCode = `<img src="https://pulpmagbackoffice.pulp.ph/storage/${imageUrl}" alt="Image" />`;
        // Copy embed code to clipboard
        navigator.clipboard.writeText(embedCode)
            .then(() => {
                // Alert that code has been copied
                alert('Embed code copied to clipboard!');
            })
            .catch((error) => {
                console.error('Error copying to clipboard:', error);
            });
        setShowModal(false);
    };

    return (
        <div>
            <Button variant='danger' onClick={() => setShowModal(true)}>Choose Image From Database</Button>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Selector</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {images.map((image) => (
                            <div key={image.id} className="col-md-3 mb-3">
                                <div className="thumbnail" onClick={() => handleImageSelect(image.url)}>
                                    <img src={`https://pulpmagbackoffice.pulp.ph/storage/${image.url}`} alt="Image" className="img-fluid" />
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
