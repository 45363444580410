import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Nav from '../base/nav';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import RichTextEditor from '../RichTextEditor'; // Ensure this import is correct
import ChooseImage from '../Modal/ChooseImage';
import Sidebar from '../base/sidebar';
import { Button } from 'react-bootstrap';
import { BsEye, BsTrash } from 'react-icons/bs';

export default function ShortStory() {
    const location = useLocation();
    const id = location.state && location.state.id;
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [seo_title, setSeo_Title] = useState('');
    const [description, setDescription] = useState('');
    const [body, setBody] = useState('');
    const [sub_head, setSubHead] = useState('');
    const [photo_by, setPhotoBy] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [item, setItem] = useState<any | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        if (id) {
            fetchItem(id);
        }
    }, [id]);

    const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCategory(e.target.value);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleSeoTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSeo_Title(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleBodyChange = (content: string) => {
        setBody(content);
    };

    const handleSubHeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubHead(e.target.value);
    };

    const handlePhotoByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhotoBy(e.target.value);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setImage(e.target.files[0]);
        }
    };

    const navigate = useNavigate();

    const fetchItem = async (id: number) => {
        try {
            const response = await axios.get(`https://pulpmagbackoffice.pulp.ph/api/short-stories/${id}`); // Fetch item based on ID
            const shortStory = response.data.shortStory;
            if (shortStory) {
                const { category, title, body, seo_title, description, sub_head, photo_by } = shortStory;
                setItem(shortStory);
                setCategory(category);
                setTitle(title);
                setBody(body);
                setSeo_Title(seo_title);
                setDescription(description);
                setSubHead(sub_head);
                setPhotoBy(photo_by);
                console.log(shortStory);
            }
        } catch (error) {
            console.error('Error fetching item:', error);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('category', category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);
        formData.append('photo_by', photo_by);
        if (image) {
            formData.append('image', image);
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            await axios.post(`https://pulpmagbackoffice.pulp.ph/api/shortStory/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                }
            });
            alert('Content Submitted Successfully !!'); // Handle success (e.g., show a success message or redirect)
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            alert(`There was an error submitting the form!, ${error}`);
            // Handle error (e.g., show an error message)
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 3000);
        }
    };


    const handleSaveDraft = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaved(true);

        const formData = new FormData();
        formData.append('category', category);
        formData.append('title', title);
        formData.append('body', body);
        formData.append('seo_title', seo_title);
        formData.append('description', description);
        formData.append('sub_head', sub_head);
        formData.append('photo_by', photo_by);
        if (image) {
            formData.append('image', image);
        }

        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                // Handle the case where authToken is not available
                alert('Authentication token not found!');
                setIsSubmitting(false);
                return;
            }

            await axios.post(`https://pulpmagbackoffice.pulp.ph/api/shortStory/save/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                }
            });
            alert('Draft Saved Successfully !!'); // Handle success (e.g., show a success message or redirect)
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            alert(`There was an error submitting the form!, ${error}`);
            // Handle error (e.g., show an error message)
        } finally {
            setTimeout(() => {
                setIsSaved(false);
            }, 3000);
        }
    };

    const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem('authToken');
            if (!authToken) {
                alert('Authentication token not found!');
                return;
            }

            const confirmDelete = window.confirm('Are you sure you want to delete this content?');
            if (!confirmDelete) {
                return; // If user cancels, do nothing
            }

            if (id) {
           await axios.delete(`https://pulpmagbackoffice.pulp.ph/api/shortStory/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                }
            })};
            alert('Content Deleted Successfully !!');
            navigate('/dashboard');
        } catch (error) {
            console.error('There was an error deleting the content!', error);
            alert(`There was an error deleting the content!, ${error}`);
        }
    };


     
    useEffect(() => {
        const storedRole = localStorage.getItem('role');
        setRole(storedRole);
    }, []);

    const handleViewShortstory = (id: number) => {
        window.open(`https://richardcalipay.pulp.ph/article/${id}`, '_blank');
    };

    return (
        <>
            <div className='flex font-graphik'>
                <Sidebar />
                <main className="container flex justify-center bg-gray-100">
                    <div className="w-full">
                        <div className='Dashboard-container flex place-content-between'>
                            <h1 className='mt-5 font-semibold'>Short Story Editor</h1>
                            <div className='mt-5'>
                            {(role === 'Magazine' || role === 'Live') && (<Button variant='danger' type='button' onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                                
                            )}                                <Button variant='secondary m-2' type='button' onClick={handleSaveDraft} >{isSaved ? 'Saving...' : 'Save as Draft'}</Button>
                                <button type='button' onClick={() => handleViewShortstory(id)} data-tooltip-id='preview' className='btn btn-danger text-2xl'><BsEye /></button>
                            </div>
                        </div>
                        <div className='container mt-5 pt-5 px-4 rounded bg-white'>
                            <h2 className='mb-5 font-semibold'> Content</h2>
                            <Form>
                                <Form.Group className="mb-3" controlId="formCategory">
                                    <label className='text-sm mb-2'>Category</label>
                                    <Form.Select aria-label="Select Category" className='bg-gray-200' value={category} onChange={handleCategoryChange}>
                                        <option value="">Select Category</option>
                                        <option value="Music">Music</option>
                                        <option value="Culture">Culture</option>
                                        <option value="MoviesTV">Movies & TV</option>
                                        {/* Add more categories as needed */}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formTitle">
                                    <label className='text-sm mb-2'>Title</label>
                                    <FloatingLabel controlId="floatingInput" label="Title" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            value={title}
                                            className='bg-gray-200'
                                            onChange={handleTitleChange}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSubHead">
                                    <label className='text-sm mb-2'>Sub Head</label>
                                    <FloatingLabel controlId="floatingInput" label="Sub Head" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Sub Head"
                                            value={sub_head}
                                            className='bg-gray-200'
                                            onChange={handleSubHeadChange}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formImage">
                                    <Form.Label className='text-sm mb-2'>Image Banner</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBody">
                                    <Form.Label className='text-sm mb-2'>Body</Form.Label>
                                    <RichTextEditor value={body} onChange={handleBodyChange} />
                                </Form.Group>

                                {/* ChooseImage component */}
                                <ChooseImage onImageSelect={() => { }} />
                                <br />


                                <h2 className='font-semibold text-xl mb-3'> SEO Details</h2>
                                <Form.Group className="mb-3" controlId="formSeoTitle">
                                    <Form.Label className='text-sm mb-2'>Seo Title</Form.Label>
                                    <FloatingLabel controlId="floatingInput" label="Seo Title" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Seo_Title"
                                            value={seo_title}
                                            className='bg-gray-200'
                                            onChange={handleSeoTitleChange}
                                        />
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formSeoDescription">
                                    <Form.Label className='text-sm mb-2'>Seo Description</Form.Label>
                                    <FloatingLabel controlId="floatingInput" label="Seo Description" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="description"
                                            value={description}
                                            className='bg-gray-200'
                                            onChange={handleDescriptionChange}
                                        />
                                    </FloatingLabel>
                                </Form.Group>
                                <div className='w-full border-1 border-black rounded mb-5'> </div>
                            </Form>

                            <h2 className='font-semibold text-xl mb-3'> Story Details</h2>

                            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'> Created </h2>
                            <p></p>
                            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'> Last Edited</h2>
                            <p></p>
                            <h2 className='mb-5 font-semibold' data-tooltip-id='feature'> Published</h2>
                            <p></p>

                            <Button variant='secondary m-2' type='button' onClick={handleDelete} >Delete</Button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
